<template>
  <header :class="['header-controller', rootClasses]">
    <div class="header-controller-default">
      <HeaderLogo class="header-controller-default__logo" :hide-background="isOpenedBurger" />
      <div v-if="changeXL(true, false)" class="header-controller-default__content">
        <div class="header-controller-default__content-controls">
          <HeaderSwitcher v-if="!hideProjectSwitcher" :active-game="EHeaderSwitcherGames.DOTA" />
          <LazyLocalizationLocaleSwitcherController />
        </div>
        <div class="header-controller-default__content-items">
          <HeaderMenuItem
            v-for="item in menuItems"
            :key="item.key"
            :badge="menuBadges[item.key]?.value || ''"
            :is-timer="menuBadges[item.key]?.type === 'timer'"
            :icon="item.icon"
            :link="item.link"
            :tooltip="item.tooltip"
            @click="handleMenuClick(item.key)"
          />
        </div>
        <SharedKitButton
          class="header-controller-default__content-button"
          :color-preset="eventButtonPreset"
          :text="eventTitle"
          :link-props="{ to: eventButtonComputed }"
          v-bind="burgerButtonPreset"
          @click="ProfileEvents.eventClicked"
        />
      </div>

      <section v-if="isAuth" class="header-controller-default__profile">
        <Transition name="fade" mode="out-in">
          <HeaderProfile
            v-if="profileData"
            v-show="!isOpenedBurger"
            v-bind="profileData"
            :is-volume-on="!isMuteCookie"
            @volume-change="handleVolumeChange"
            @logout="handleLogout"
          />
        </Transition>
        <RewardPopupController v-if="!hideRewardPopup" v-show="!isOpenedBurger" />
      </section>
      <GeneralAuthButtonController
        v-else-if="changeXL(true, false)"
        class="header-controller__auth-btn"
        :amplitude-source-of-click="EAuthButtonPlaceClicked.HEADER"
      />

      <div v-if="changeXL(false, true)" class="header-controller-default__buttons">
        <Transition name="fade" mode="out-in">
          <div v-if="isOpenedBurger && isAuth" class="header-controller-default__buttons-burger__controls">
            <SharedKitButton
              class="header-controller-default__buttons-burger"
              :color-preset="ColorPresets.DOTA.PRIMARY"
              v-bind="defaultMobileButtonPreset"
              @click="handleVolumeChange"
            >
              <template #prepend>
                <Transition name="fade" mode="out-in">
                  <SvgoVolumeOnIcon v-if="!isMuteCookie" class="header-controller-default__buttons-burger--icon" />
                  <SvgoVolumeOffIcon v-else class="header-controller-default__buttons-burger--icon" />
                </Transition>
              </template>
            </SharedKitButton>
            <SharedKitButton
              class="header-controller-default__buttons-burger"
              :color-preset="ColorPresets.DOTA.PRIMARY"
              v-bind="defaultMobileButtonPreset"
              @click="handleLogout"
            >
              <template #prepend>
                <SvgoLogoutIcon class="header-controller-default__buttons-burger--icon" />
              </template>
            </SharedKitButton>
          </div>
          <SharedKitButton
            v-else-if="isAuth"
            class="header-controller-default__buttons-item"
            :color-preset="ColorPresets.DOTA.ACCENT"
            v-bind="defaultMobileButtonPreset"
            :link-props="{ to: ROUTING.PAYMENT.MAIN.path }"
            @click="PaymentEvents.paymentOpened({ buttonPlace: EPaymentOpenedButtonPlaces.HEADER })"
          >
            <template v-if="userStore.user?.isFake && changeMD(false, true)" #prepend>
              <span class="header-controller-default__buttons-item--diamond"> +💎</span>
            </template>
            <template v-else-if="!userStore.user?.isFake && changeMD(false, true)" #prepend>
              <SvgoHeaderBalanceM class="header-controller-default__buttons-item--icon" />
            </template>
            <template v-else #prepend>
              <span class="header-controller-default__buttons-item--text">{{ $t('topupBalance') }}</span>
            </template>
          </SharedKitButton>
          <GeneralAuthButtonController
            v-else-if="!isAuth && !isOpenedBurger"
            is-in-header
            :amplitude-source-of-click="EAuthButtonPlaceClicked.HEADER"
          />
        </Transition>
        <SharedKitButton
          class="header-controller-default__buttons-burger"
          :color-preset="ColorPresets.DOTA.PRIMARY"
          v-bind="defaultMobileButtonPreset"
          @click="isOpenedBurger = !isOpenedBurger"
        >
          <template #prepend>
            <Transition name="fade" mode="out-in">
              <SvgoHeaderBurgerClose v-if="isOpenedBurger" class="header-controller-default__buttons-burger--icon" />
              <SvgoHeaderBurgerOpen v-else class="header-controller-default__buttons-burger--icon" />
            </Transition>
          </template>
        </SharedKitButton>
      </div>
    </div>
    <div v-if="isOpenedBurger" class="header-controller-burger">
      <LazyLocalizationLocaleSwitcherController class="header-controller-burger__locale" />
      <div v-if="isAuth" class="header-controller-burger__profile">
        <HeaderProfile v-if="profileData" v-bind="profileData" is-for-burger />
        <SharedKitButton
          class="header-controller-burger__profile-button"
          :color-preset="ColorPresets.DOTA.ACCENT"
          v-bind="burgerButtonPreset"
          :text="$t('topupBalance')"
          :link-props="{ to: ROUTING.PAYMENT.MAIN.path }"
          @click="PaymentEvents.paymentOpened({ buttonPlace: EPaymentOpenedButtonPlaces.HEADER })"
        />
      </div>
      <GeneralAuthButtonController
        v-else
        class="header-controller__auth-drawer-btn"
        is-in-burger
        :amplitude-source-of-click="EAuthButtonPlaceClicked.HEADER"
      />
      <HeaderSwitcher v-if="!hideProjectSwitcher" :active-game="EHeaderSwitcherGames.DOTA" />
      <div class="header-controller-burger__shortcuts">
        <HeaderMenuItem
          v-for="item in menuItems"
          :key="item.key"
          :badge="menuBadges[item.key]?.value || ''"
          :is-timer="menuBadges[item.key]?.type === 'timer'"
          :icon="item.icon"
          :link="item.link"
          :tooltip="item.tooltip"
          @click="handleMenuClick(item.key)"
        />
      </div>
      <div class="header-controller-burger__menu">
        <h3 class="header-controller-burger__menu-title">{{ $t('header.burgerTitle') }}</h3>

        <SharedKitButton
          v-if="eventTitle"
          class="header-controller-burger__menu-button"
          :color-preset="eventButtonPreset"
          v-bind="burgerButtonPreset"
          :text="eventTitle"
          :link-props="{ to: eventButtonComputed }"
          @click="handleEventClick"
        />

        <SharedKitButton
          v-for="tabName in activeTabs"
          :key="tabName"
          class="header-controller-burger__menu-button"
          :color-preset="ColorPresets.DOTA.BURGER"
          v-bind="burgerButtonPreset"
          :text="MainPageActiveTabsPreset($t)[tabName].text"
          :link-props="{ to: MainPageActiveTabsPreset($t)[tabName].to, external: true }"
          @click="isOpenedBurger = !isOpenedBurger"
        >
          <template v-if="MainPageActiveTabsPreset($t)[tabName].coinIcon" #append>
            <NuxtImg v-if="coinIcon" :src="coinIcon" :width="16" :height="16" />
          </template>
        </SharedKitButton>
      </div>
    </div>
  </header>
</template>

<script setup lang="ts">
import { MENU_ITEMS_CONFIG } from './HeaderController.data';
import { EHeaderMenuItems } from '~/config/apps/index.types';
import type { IHeaderProps } from '~/controllers/HeaderController/HeaderController.types';
import { EHeaderSwitcherGames } from '~/components/HeaderSwitcher/HeaderSwitcher.types';
import { EMainPageEvents } from '~/features/mainPage/constants/events.constants';

import RewardPopupController from '~/features/mainPage/controllers/RewardPopupController/RewardPopupController.vue';

import { ColorPresets, TypePresets, SizePresets } from '~/components/SharedKitButton/SharedKitButton.presets';
import { MainPageActiveTabsPreset } from '~/features/mainPage/presets/HeaderTab.presets';
import { EventButtonPreset } from '~/features/mainPage/presets/SharedKitButton.presets';

import { useEventStore } from '~/store/event/event.store';
import { useVolumeStore } from '~/store/volume/volume';
import { useHeaderStore } from '~/layers/dota/store/header/header.store';
import { useAuthStore } from '~/features/authentication/store/authorization';
import { useUserStore } from '~/store/user/user.store';
import { ProfileEvents } from '~/repository/amplitude/events/profile';
import { EAuthButtonPlaceClicked } from '~/repository/amplitude/types/auth.types';
import { PaymentEvents } from '~/repository/amplitude/events/payment';
import { EPaymentOpenedButtonPlaces } from '~/repository/amplitude/types/payment.types';

const {
  hideProjectSwitcher,
  hideHeaderMenuItems,
  redirects: { newCases },
} = useProjectSettings();

const defaultMobileButtonPreset = {
  sizePreset: SizePresets.XS,
  typePreset: TypePresets.ROUND,
};

defineProps<IHeaderProps>();

const { t } = useI18n();

const changeXL = GlobalUtils.Media.changeByMedia('xl');
const changeMD = GlobalUtils.Media.changeByMedia('md');

const eventStore = useEventStore();
const { eventButtonGradient, eventTitle, coinIcon, activeTabs } = storeToRefs(eventStore);

const volumeStore = useVolumeStore();
const { isMuteCookie } = storeToRefs(volumeStore);

const headerStore = useHeaderStore();
const { menuBadges, profileData } = storeToRefs(headerStore);

const authStore = useAuthStore();
const { isAuth } = storeToRefs(authStore);

const userStore = useUserStore();

const isOpenedBurger = ref(false);

const isMobileBtn = ref(false);

const rootClasses = computed(() => ({
  'header-controller--burger': isOpenedBurger.value,
}));

const menuItems = computed(() => MENU_ITEMS_CONFIG(t).filter((item) => !hideHeaderMenuItems.includes(item.key)));

const burgerButtonPreset = computed(() => ({
  sizePreset: SizePresets.M,
  typePreset: TypePresets.ROUND,
}));

const eventButtonPreset = computed(() => {
  return EventButtonPreset(eventButtonGradient.value.color, eventButtonGradient.value.hover);
});

const eventButtonComputed = computed(() => {
  const routes: Record<number, string> = {
    [EMainPageEvents.GPT]: ROUTING.MAINPAGE.NEW_CASES.path,
    [EMainPageEvents.TECHIES]: ROUTING.TECHIES.MAINPAGE.path,
  };

  return routes[eventStore.id] ?? newCases;
});

const handleMenuClick = (key: EHeaderMenuItems) => {
  isOpenedBurger.value = false;
  menuItems.value.find((item) => item.key === key)?.amplitude?.();

  if (key !== EHeaderMenuItems.SECRETSHOP) return;
  headerStore.saveViewedSecretOffers();
};

const handleEventClick = () => {
  isOpenedBurger.value = !isOpenedBurger.value;
  ProfileEvents.eventClicked();
};

const handleLogout = async () => {
  await authStore.logout();
  ProfileEvents.logOutClicked();
};

const handleVolumeChange = () => {
  volumeStore.toggleMute();
  ProfileEvents.soundClicked();
};

watch(
  () => changeXL(true, false),
  (value) => {
    if (!value) return;
    isOpenedBurger.value = false;
  },
);

watch(
  () => changeMD(true, false),
  (value) => {
    if (!value) return;
    isMobileBtn.value = false;
  },
);

watch(isOpenedBurger, (value) => {
  if (!document) return;

  const classListAction = value ? 'add' : 'remove';
  document.body.classList[classListAction]('shared-modal-opened');
});
</script>

<style scoped lang="scss" src="/controllers/HeaderController/HeaderController.scss" />
